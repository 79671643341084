import authConfig from '@/auth.config'
import axios from 'axios'
import NextAuth from 'next-auth'

const apiUrl = process.env.NEXT_PUBLIC_API_URL

export const {
	handlers: { GET, POST },
	auth,
	signIn,
	signOut,
	unstable_update: update,
} = NextAuth({
	session: {
		strategy: 'jwt',
	},
	pages: {
		signIn: '/login',
		error: '/auth/error',
	},
	callbacks: {
		async signIn({ user, account }) {
			if (account && (account.provider === 'google' || account.provider === 'facebook')) {
				try {
					// Send user data to your backend
					const res = await axios.post(`${apiUrl}/auth/google`, {
						email: user.email,
						name: user.name,
						image: user.image,
					})

					const { token, user: backendUser } = res.data

					// Attach token and user info to the user object
					user.accessToken = token
					user.id = backendUser.id
					user.role = backendUser.role
					user.hasSelectedRole = backendUser.hasSelectedRole
					user.image = backendUser.user_avatar // Update image if necessary

					return true
				} catch (error) {
					console.error('Error during Google authentication:', error)
					return false
				}
			}

			// For credentials provider
			if (user.email) {
				return true
			}

			return false
		},
		async jwt({ token, user }) {
			if (user) {
				token.accessToken = user.accessToken
				token.user = {
					id: user.id,
					name: user.name,
					email: user.email,
					role: user.role || 'CLIENT',
					user_avatar: user.image,
					hasSelectedRole: user.hasSelectedRole,
				}
			}
			return token
		},
		async session({ session, token }) {
			if (session.user && token.user) {
				session.accessToken = token.accessToken
				session.user = token.user
			}
			return session
		},
		async redirect({ url, baseUrl }) {
			// Default behavior: redirect to `url` if it's a relative path, otherwise to `baseUrl`
			return url.startsWith(baseUrl) ? url : baseUrl
		},
	},
	...authConfig,
})
