import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/lib/utils'

/* focus-visible:ring-2 */

const buttonVariants = cva(
	'tracking-wide focus-visible:ring-ring focus-visible:ring-offset-2 ring-offset-background inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50',
	{
		variants: {
			variant: {
				default: 'bg-primary text-white hover:bg-primary/90 w-44',
				destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
				soft: 'bg-transparent text-text-title hover:shadow-md',
				//soft is a temporary solution for buttons that need to be transparent, have a hover effect and should contain small css animations in the future
				outline: 'border border-muted-foreground bg-background hover:bg-accent hover:text-accent-foreground',
				secondary: 'bg-secondary text-white hover:bg-secondary/80 w-44',
				ghost: 'hover:bg-accent hover:text-accent-foreground',
				link: 'text-primary underline-offset-4 hover:underline',
			},
			size: {
				default: 'px-4 h-10 py-2',
				sm: 'h-9 rounded-md px-3',
				lg: 'h-11 rounded-md px-8',
				icon: 'h-10 w-10',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	},
)

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button'
		return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
	},
)
Button.displayName = 'Button'

export { Button, buttonVariants }
