//useSchemas.ts

import { useTranslation } from 'react-i18next'
import { z } from 'zod'

const clientSideFileSchema = typeof window !== 'undefined' ? z.instanceof(File) : z.any()

export const usePostSchema = () => {
	const { t } = useTranslation('posts')
	return z.object({
		title: z.string().min(1, { message: t('title_required') }),
		description: z.string().min(1, { message: t('description_required') }),
		tags: z.array(z.string()),
		post_image: z.union([clientSideFileSchema, z.string(), z.null()]).optional(),
		published: z.boolean().optional(),
	})
}

export const useProfileSchema = () => {
	const { t } = useTranslation('profile')
	return z.object({
		name: z.string().min(1, { message: t('name_required') }),
		user_avatar: z.union([clientSideFileSchema, z.string(), z.null()]).optional(),
	})
}

export const useUserSchema = (isCreating: boolean) => {
	const { t } = useTranslation('sign_up')
	return z
		.object({
			name: z.string().min(1, { message: t('required') }),
			username: z.string().min(1, { message: t('required') }),
			email: z.string().email({ message: t('invalid_email') }),
			confirmEmail: z.string().email({ message: t('invalid_email') }),
			role: z.enum(['CLIENT', 'COACH'], { errorMap: () => ({ message: t('invalid_role') }) }),
			password: isCreating
				? z
						.string()
						.min(6, { message: t('password_min_length') }) // Password required during creation
				: z.string().optional(), // Password optional during edit
			confirmPassword: isCreating ? z.string().min(6, { message: t('password_min_length') }) : z.string().optional(),
		})
		.refine((data) => (isCreating ? data.password === data.confirmPassword : true), {
			message: t('passwords_do_not_match'),
			path: ['confirmPassword'],
		})
}

export const useLoginSchema = () => {
	const { t } = useTranslation('login')
	return z.object({
		usernameOrEmail: z.string().min(1, { message: t('username_or_email_required') }),
		password: z.string().min(1, { message: t('password_required') }),
	})
}

export const useForgotPasswordSchema = () => {
	const { t } = useTranslation('forgot_password')
	return z.object({
		email: z
			.string()
			.email({ message: t('invalid_email_address') })
			.min(1, { message: t('email_required') }),
	})
}

export const useProtocolSchema = () => {
	const { t } = useTranslation('protocols')
	return z.object({
		title: z.string().min(1, { message: t('title_required') }),
		subtitle: z.string().optional(),
		type: z.enum(['WORKOUT', 'DIET', 'OTHER'], {
			errorMap: () => ({ message: t('type_required') }),
		}),
		item: z.string().optional(),
		quantity: z.string().optional(),
		impact: z.string().optional(),
	})
}

export const useProtocolItemSchema = () => {
	const { t } = useTranslation('protocols')
	return z.object({
		item: z.string().min(1, { message: t('item_required') }),
		quantity: z.string().min(1, { message: t('quantity_required') }),
		impact: z.string().optional(),
	})
}

export const useResetPasswordSchema = () => {
	const { t } = useTranslation('reset_password')
	return z
		.object({
			password: z.string().min(6, { message: t('password_min_length') }),
			confirmPassword: z.string().min(6, { message: t('password_min_length') }),
		})
		.refine((data) => data.password === data.confirmPassword, {
			message: t('passwords_dont_match'),
			path: ['confirmPassword'],
		})
}

export const useProgramSchema = () => {
	const { t } = useTranslation('programs')
	return z.object({
		program_name: z.string().min(1, { message: t('program_name_required') }),
		goal: z.string().min(1, { message: t('goal_required') }),
		duration_in_weeks: z.number().min(1, { message: t('duration_in_weeks_required') }),
		program_description: z.string().optional(),
		clientIds: z.array(z.string()).optional(),
	})
}
